<template>
  <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left">

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t('General') }}</span>
      </template>

      <course-setting-general v-if="courseData.data" :courseId="courseData.data.id" :field-information="'general'"
        :general-data="courseData" />
    </b-tab>


    <b-tab>


      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t('Certificate Designer') }}</span>
      </template>

      <certificate-designer v-if="courseData.data" title="Certification Design" :id="courseData.data.id"
        :courseId="courseData.data.id" :field-information="'certificateHtml'"
        :information-data="courseData.data.certificateHtml" />
    </b-tab>
    <b-tab>



      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t('Certification Preview') }}</span>
      </template>

      <course-info-content-info v-if="courseData.data" :title="$t('Certification Preview')" :id="courseData.data.id"
        :courseId="courseData.data.id" :field-information="'certificateHtml'"
        :information-data="courseData.data.certificateHtml" />
    </b-tab>






    <!-- 
    

    -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import courseStoreModule from '../certificateStoreModule'
import CourseSettingGeneral from './CertificateInfoGeneral.vue'
import CourseSettingInformation from './CertificateInfoInformation.vue'
import CourseSettingNotification from './CertificateInfoNotification.vue'
import CourseInfoContentInfo from './CertificateInfoContentInfo.vue'
import CourseInfoCoursePreference from './CertificateInfoCoursePreference.vue'
import CourseInfoPricing from './CertificateInfoPricing.vue'
import CourseInfoCertification from './CertificateInfoCertification.vue'
import CertificateDesigner from './CertificateDesign.vue'

export default {
  components: {
    BTabs,
    BTab,
    CourseSettingGeneral,
    CertificateDesigner,
    CourseSettingInformation,

    CourseSettingNotification,
    CourseInfoCoursePreference,
    CourseInfoPricing,
    CourseInfoCertification,
    CourseInfoContentInfo
  },

  setup() {

    const USER_APP_STORE_MODULE_NAME = 'apps-certificate-edit'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, courseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('apps-certificate-edit/fetchCertificate', { id: router.currentRoute.query.id })
    const courseData = computed(() => {
      return store.getters['apps-certificate-edit/getCertificate'] ?? { id: 0 }
    })

    return {
      courseData,
    }
  },
  data() {
    return {
      courseInfo: JSON.parse(JSON.stringify(this.courseData)),
    }
  },

}
</script>
